<template>
  <div class="login">
    <img class="bg" :src="data.bg" alt="">
    <div class="content">
      <div class="form">
        <div class="title">登录</div>
        <div class="line">
          <div class="name">用户名</div>
          <div class="input_box">
            <input
              v-model="data.username"
              type="text"
              class="input"
              autocomplete="false"
              placeholder="请输入用户名"
            />
          </div>
        </div>
        <div class="line">
          <div class="name">密码</div>
          <div class="input_box">
            <input
              v-model="data.password"
              type="password"
              class="input"
              autocomplete="false"
              placeholder="请输入密码"
            />
          </div>
        </div>
        <div class="btn_box">
          <div class="btn" @click="login">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { useRouter } from 'vue-router';
import tips from '@/components/Tips/index';
import { useUser } from '@/store/user';
export default defineComponent({
  name: 'AdminLogin',
  setup() {
    const data = reactive({
      username: '',
      password: '',
      bg: '/static/images/bg5.jpeg',
    });

    const userStore = useUser();
    const router = useRouter();

    const login = async() => {
      if (!data.username) {
        tips('请输入用户名');

        return;
      }
      if (!data.password) {
        tips('请输入密码');

        return;
      }
      await userStore.login(data.username, data.password);
      tips('登录成功');
      router.push({ path: '/admin' });
    };

    return { data, login };
  },
});
</script>
<style lang="less" scoped>
@import "../../styles/config.less";
.login {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @c_bg_tips;
  .bg{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
  .content {
    position: relative;
    z-index: 10;
    left: 3rem;
    width: 4rem;
    padding: 0.1rem 0.2rem;
    border-radius: 0.1rem;
    background-color: @c_white;
    box-shadow: 0 0 0.5rem 0.1rem fade(@c_tips, 50);
    .form {
      padding: 0.2rem 0.2rem;
      .title {
        padding: 0.1rem 0;
        font-size: 0.3rem;
        font-weight: bold;
        color: @c_active;
        line-height: 1.5;
        text-align: center;
      }
      .line {
        padding: 0.15rem 0;
        display: flex;
        align-items: center;
        .name {
          width: 0.8rem;
          font-size: 0.2rem;
          color: @c_title;
          line-height: 1.5;
        }
        .input_box {
          flex: 1;
          .input {
            display: block;
            width: 100%;
            height: 0.32rem;
            padding: 0 0.1rem;
            border: none;
            border-radius: 0.2rem;
            background-color: transparent;
            font-size: 0.2rem;
            color: @c_title;
            line-height: 1.5;
            &:focus {
              outline: none;
            }
          }
        }
      }
      .btn_box {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1rem 0;
        .btn {
          width: 1.6rem;
          height: 0.4rem;
          border-radius: 0.25rem;
          background-color: @c_bg_active;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.2rem;
          color: @c_title;
          cursor: pointer;
          &:hover {
            box-shadow: 0 0 0.5rem 0.05rem @c_bg_tips;
          }
        }
      }
    }
  }
}
</style>
